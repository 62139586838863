import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'components/layout'

const Bios = ({ data }) => {
  const person = data.markdownRemark.frontmatter
  return (
    <Layout isModal={true} modalBackground={'/fundamentals/farm-team'}>
      <div className="bio__header">
        <Img sizes={person.picture.childImageSharp.fluid} />
        
        <h1>{person.first_name} {person.last_name}</h1>
        <p className="bio__title">{person.title_long}</p>
      </div>
      <div className="bio__contents">
        <dl className="bio__details">
          <dt>Email</dt>
          <dd><a href={`mailto:${person.email}`}>{person.email}</a></dd>
          <dt>Phone</dt>
          <dd>{person.phone}</dd>
        </dl>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        first_name
        last_name
        picture {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        title_long
        phone
        email
      }
    }
  }
`

export default Bios